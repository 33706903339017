// import './clock';
// import './carousel';
import './menu';
import './woo';
import './posts/loadmore';

// Styles
import '../sass/main.scss';

// Images
import '../img/patterns/bg.jpeg';
import '../img/patterns/c1-150x150.jpeg';
import '../img/patterns/c2-150x150.jpeg';
import '../img/patterns/c3-150x150.jpeg';
// import '../img/email.webp';
import '../img/payment.webp';
// import '../img/secure-with-macfee.webp';

jQuery(document).ready(function($) {
  $('.slider-main, .wc-block-grid__products').slick({
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });
  $('.wp-block-latest-posts__list').slick({
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });

  $('.glsr-reviews').slick({
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });

  // Product category menu open when click on it - Start
  $('.account-link-main .menu-item-account').hover(
    function () {
      $('.asgard-account-menu, .asgard-account-signin').addClass('d-block visible');
      $('.asgard-account-menu, .asgard-account-signin').removeClass('d-none invisible');
    },
    function () {
      $('.asgard-account-menu, .asgard-account-signin').removeClass('d-block visible');
      $('.asgard-account-menu, .asgard-account-signin').addClass('d-none invisible');
    }
  );
  $('.asgard-account-menu, .asgard-account-signin').hover(
    function () {
      $(this).addClass('d-block visible');
      $(this).removeClass('d-none invisible');
    },
    function () {
      $(this).removeClass('d-block visible');
      $(this).addClass('d-none invisible');
    }
  );
});